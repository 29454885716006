export const ubuntu_names = {
    "a": {
        "adjectives": [
            "Able",
            "Acute",
            "Adventurous",
            "Agile",
            "Aggravated",
            "Alcoholic",
            "Alliterating",
            "Amok",
            "Angelic",
            "Antsy",
            "Amazing",
            "Ambling",
            "Amenable",
            "Amicable",
            "Anarchic",
            "Annoying",
            "Antidisestablishmentarian",
            "Androgynous",
            "Archaic",
            "Arty",
            "Atomic",
            "Atrophying",
            "Awesome",
            "Aware",
            "Aztec",
            "Antlered"
        ],
        "animals": [
            "Aal",
            "Aardvark",
            "Affenpinscher",
            "Agama",
            "Albatross",
            "Alebrije",
            "Alien",
            "Alligator",
            "Alpaca",
            "Amoeba",
            "Anemone",
            "Angelfish",
            "Anoa",
            "Ant",
            "Anteater",
            "Antelope",
            "Ape",
            "Arachnid",
            "Armadillo",
            "Asp",
            "Avocet",
            "Axolotl",
            "Aye Aye"
        ],
        "version": { "initial_year": 17, "month": 10 }
    },
    "b": {
        "adjectives": [
            "Ballsy",
            "Batty",
            "Barking",
            "Belching",
            "Bellicose",
            "Bewildered",
            "Blustering",
            "Boggling",
            "Boisterous",
            "Bonkers",
            "Bounding",
            "Brainy",
            "Brave",
            "Brazen",
            "Breezy",
            "Brilliant",
            "Bright",
            "Bumpy",
            "Busy"
        ],
        "animals": [
            "Baboon",
            "Badger",
            "Barnacle",
            "Barracuda",
            "Bat",
            "Beagle",
            "Bear",
            "Beaver",
            "Bee",
            "Beetle",
            "Bison",
            "Bitch",
            "Bittern",
            "Blackbird",
            "Blowfish",
            "Bluejay",
            "Bobcat",
            "Bonobo",
            "Bothrop",
            "Bowerbird",
            "Buck",
            "Bull",
            "Bunny"
        ],
        "version": { "initial_year": 18, "month": 4 }
    },
    "c": {
        "adjectives": [
            "Calculating",
            "Callous",
            "Campy",
            "Canny",
            "Canonical",
            "Careful",
            "Cavalier",
            "Celebrating",
            "Charming",
            "Cheeky",
            "Cheerful",
            "Cheesy",
            "Chirping",
            "Chocolate",
            "Chronic",
            "Chummy",
            "Clever",
            "Clumsy",
            "Colonel",
            "Constipated",
            "Cool",
            "Courageous",
            "Crabby",
            "Crafty",
            "Cranky",
            "Crazy",
            "Crooked",
            "Cruising",
            "Cuddly",
            "Culpable",
            "Cunning",
            "Cute",
            "Curt",
            "Cosmic"
        ],
        "animals": [
            "Camel",
            "Capybara",
            "Caracal",
            "Cardinal",
            "Caribou",
            "Cassowary",
            "Cat",
            "Caterpillar",
            "Centipede",
            "Chameleon",
            "Chapulín",
            "Cheetah",
            "Chickadee",
            "Chicken",
            "Chimp",
            "Chinchilla",
            "Chipmunk",
            "Chupacabra",
            "Coati",
            "Cobra",
            "Cockatoo",
            "Condor",
            "Cony",
            "Coot",
            "Cougar",
            "Cow",
            "Coyote",
            "Crab",
            "Crane",
            "Crocodile",
            "Crow",
            "Cuttlefish"
        ],
        "version": { "initial_year": 18, "month": 10 }
    },
    "d": {
        "adjectives": [
            "Dapper",
            "Dangerous",
            "Daring",
            "Dastardly",
            "Daunting",
            "Dazzling",
            "Defiant",
            "Diligent",
            "Disaffected",
            "Disorderly",
            "Dogmatic",
            "Dreamy",
            "Drowsy",
            "Durable",
            "Dutiful"
        ],
        "animals": [
            "Deer",
            "Devil",
            "Dingo",
            "Diplodocus",
            "Dodo",
            "Doe",
            "Dog",
            "Dolphin",
            "Donkey",
            "Dormouse",
            "Dove",
            "Dragon",
            "Drake",
            "Dryad",
            "Duck",
            "Dugong",
            "Dungbeetle",
            "Dzo"
        ],
        "version": { "initial_year": 19, "month": 4 }
    },
    "e": {
        "adjectives": [
            "Eager",
            "Early",
            "Earnest",
            "Easy",
            "Eccentric",
            "Eclectic",
            "Edgy",
            "Eel",
            "Eerie",
            "Effervescent",
            "Electric",
            "Eminent",
            "Elegant",
            "Elite",
            "Eloquent",
            "Embraceable",
            "Enlightened",
            "Enoumous",
            "Ergonomic",
            "Erratic",
            "Euphoric",
            "Evangelizing",
            "Exquisite",
            "Extrovert",
            "Eked"
        ],
        "animals": [
            "Eagle",
            "Eagle",
            "Earthworm",
            "Earwig",
            "Echidna",
            "Eel",
            "Eft",
            "Eland",
            "Elephant",
            "Elf",
            "Elk",
            "Emu",
            "Ermine",
            "Escargot",
            "Euglena",
            "Ewe"
        ],
        "version": { "initial_year": 19, "month": 10 }
    },
    "f": {
        "adjectives": [
            "Fair",
            "Fairies",
            "Fanatical",
            "Fantastic",
            "Farsighted",
            "Fast",
            "Fatuous",
            "Fecund",
            "Feisty",
            "Festive",
            "Ferocious",
            "Fierce",
            "Fiery",
            "Fishy",
            "Fitchew",
            "Flamboyant",
            "Flakey",
            "Flashy",
            "Flippant",
            "Flirty",
            "Fluent",
            "Flying",
            "Foxy",
            "Freaky",
            "Friendly",
            "Frisky",
            "Froody",
            "Fruity",
            "Functional",
            "Funky",
            "Furious",
            "Furry",
            "Fuzzy"
        ],
        "animals": [
            "Falcon",
            "Fawn",
            "Feline",
            "Fenestrator",
            "Fenix",
            "Fennec",
            "Ferret",
            "Fieldmouse",
            "Finch",
            "Finfoot",
            "Firefly",
            "Fish",
            "Flamingo",
            "Flight",
            "Fly",
            "Foundling",
            "Fox",
            "Frog",
            "Fruitbat",
            "Fugu",
            "Furbolg"
        ],
        "version": { "initial_year": 20, "month": 4 }
    },
    "g": {
        "adjectives": [
            "Gaia",
            "Gallant",
            "Galloping",
            "Gallus",
            "Gargantuan",
            "Gelatinous",
            "Giddy",
            "Genderqueer",
            "Giggling",
            "Glad",
            "Gleaming",
            "Gnathonic",
            "Gnathal",
            "Gnomic",
            "Gnostic",
            "Gooey",
            "Glorious",
            "Goofy",
            "Gol",
            "Gorgeous",
            "Graceful",
            "Gracious",
            "Great",
            "Greedy",
            "Gregarious",
            "Grinning",
            "Gritty",
            "Grizzly",
            "Groovy",
            "Grumpy",
            "Gutsy"
        ],
        "animals": [
            "Galah",
            "Gannet",
            "Gappu",
            "Gator",
            "Gazelle",
            "Gecko",
            "Gelding",
            "Gerbil",
            "Ghost",
            "Gibbon",
            "Giraffe",
            "Gnarwhal",
            "Gnat",
            "Gnu",
            "Goat",
            "Gobbler",
            "Goblin",
            "Goldfinch",
            "Goose",
            "Gopher",
            "Gorilla",
            "Goshawk",
            "Grebe",
            "Groundhog",
            "Grue",
            "Gryphon",
            "Guanaco",
            "Gull",
            "Guppy",
            "Gurk"
        ],
        "version": { "initial_year": 20, "month": 10 }
    },
    "h": {
        "adjectives": [
            "Hairy",
            "Happy",
            "Hardy",
            "Haughty",
            "Hazardous",
            "Helpful",
            "Helpless",
            "Hilarious",
            "Hippy",
            "Hoary",
            "Holy",
            "Hoopy",
            "Hopping",
            "Horned",
            "Horny",
            "Horrible",
            "Hot",
            "Howling",
            "Huge",
            "Humble",
            "Humourous",
            "Humpin",
            "Hungry",
            "Hyperactive"
        ],
        "animals": [
            "Hamster",
            "Hare",
            "Harrier",
            "Hartebeest",
            "Hawk",
            "Hedgehog",
            "Heffalump",
            "Hen",
            "Heron",
            "Hippo(potamus)",
            "Hoopoe",
            "Hornet",
            "Horse",
            "Horus",
            "Hounddog",
            "Human",
            "Hummingbird",
            "Humpback",
            "Husky",
            "Hyena",
            "Hypnotoad"
        ],
        "version": { "initial_year": 20, "month": 4 }
    },
    "i": {
        "adjectives": [
            "Icky",
            "Icy",
            "Idyllic",
            "Iffy",
            "Igneous",
            "Ignited",
            "Illuminating",
            "Illustrious",
            "Immodest",
            "Immortal",
            "Impish",
            "Impressive",
            "Incendiary",
            "Incomparable",
            "Inconceivable",
            "Incontinent",
            "Incredible",
            "Incriminatory",
            "Indefatigable",
            "Indelible",
            "Industrious",
            "Inebriated",
            "Ineffable",
            "Inescapable",
            "Inestimable",
            "Inevitable",
            "Inexorable",
            "Infallible",
            "Inflammatory",
            "Inflationary",
            "Ingenious",
            "Ingratiating",
            "Initiatory",
            "Inky",
            "Innocent",
            "Innovatory",
            "Inquisitive",
            "Insanitary",
            "Insatiable",
            "Inscrutable",
            "Insightful",
            "Insolent",
            "Insouciant",
            "Inspirational",
            "Inspired",
            "Insurgent",
            "Intelligent",
            "Interagency",
            "Intercalary",
            "Intercessory",
            "Intercity",
            "Intermediary",
            "Interstellar",
            "Intervarsity",
            "Intimate",
            "Intimidated",
            "Intrepid",
            "Investigatory",
            "Irate",
            "Iridescent",
            "Irie",
            "Irksome",
            "Irrefutable",
            "Itchy"
        ],
        "animals": [
            "Ibex",
            "Ibis",
            "Ichabodcraniosaurus",
            "Ichthyosaur",
            "Iguana",
            "Iguana",
            "Iguana",
            "Iguanodon",
            "Iguanosaurus",
            "Iliosuchus",
            "Ilokelesia",
            "Imp",
            "Impala",
            "Incisivosaurus",
            "Indosaurus",
            "Indosuchus",
            "Indri",
            "Ingenia",
            "Inkanyamba",
            "Inosaurus",
            "Insect",
            "Irritator",
            "Irukandji",
            "Isanosaurus",
            "Ischisaurus",
            "Ischyrosaurus",
            "Isisaurus",
            "Isopoda",
            "Issasaurus",
            "Itemirus",
            "Iuticosaurus",
            "Ivory"
        ],
        "version": { "initial_year": 21, "month": 10 }
    },
    "j": {
        "adjectives": [
            "Jabbering",
            "Jaded",
            "Jaundice",
            "Jaunty",
            "Jazzy",
            "Jealous",
            "Jiggly",
            "Jinchira",
            "Jittery",
            "Jiving",
            "Jocular",
            "Jocund",
            "Jodeling",
            "Jogging",
            "Jolly",
            "Jolted",
            "Jolty",
            "Jousting",
            "Jovial",
            "Joyous",
            "Jubilant",
            "Judicious",
            "Juggling",
            "Juicy",
            "Jumbled",
            "Jumping",
            "Jumpity",
            "Jumpy",
            "Jungle",
            "Jungly",
            "Jurassic",
            "Juvenile"
        ],
        "animals": [
            "Jabberwock",
            "Jacana",
            "Jackal",
            "Jackalope",
            "Jackass",
            "Jackrabbit",
            "Jackrabbit",
            "Jaguar",
            "Javelina",
            "Jay",
            "Jaybird",
            "Jellyfish",
            "Jentink's",
            "Jerboa",
            "Joey",
            "Jubata",
            "Junco",
            "Junebug"
        ],
        "version": { "initial_year": 22, "month": 4 }
    },
    "k": {
        "adjectives": [
            "Kafkaesque",
            "Kaleidoscopic",
            "Kamikaze",
            "Kanny",
            "Karmic",
            "Katie",
            "Kayoed",
            "Keeking",
            "Keen",
            "Keepable",
            "Kempy",
            "Khaki",
            "Kick-ass",
            "Kicking",
            "Kicky",
            "Killer",
            "Kilted",
            "Kind",
            "Kindhearted",
            "Kinetic",
            "King-sized",
            "Kingly",
            "Kinky",
            "Kissable",
            "Kissy",
            "Kitschy",
            "Kleptomaniacal",
            "Klutzy",
            "Knavish",
            "Knightly",
            "Knobby",
            "Knotty",
            "Kooky",
            "Kurt",
            "Kvetching"
        ],
        "animals": [
            "Kakapo",
            "Kalong",
            "Kangaroo",
            "Katydid",
            "Kea",
            "Kelpie",
            "Kestrel",
            "Kid",
            "Killdeer",
            "Kingfisher",
            "Kinkajou",
            "Kite",
            "Kitten",
            "Kittie",
            "Kittiwake",
            "Kittyhawk",
            "Kiwi",
            "Knight",
            "Koala",
            "Kodiak",
            "Kodkod",
            "Koi",
            "Kolibri",
            "Komodo",
            "Konqui",
            "Kookaburra",
            "Kouprey",
            "Kowari",
            "Kraut",
            "Krocodile",
            "Kudu"
        ],
        "version": { "initial_year": 22, "month": 10 }
    },
    "l": {
        "adjectives": [
            "Lampooning",
            "Languid",
            "Lascivious",
            "Laudable",
            "Laughable",
            "Laughing",
            "Lazy",
            "Leaky",
            "Leal",
            "Leaping",
            "Leapy",
            "Leftist",
            "Lethal",
            "Liberal",
            "Limp",
            "Lingual",
            "Listless",
            "Lithe",
            "Litigious",
            "Lively",
            "Lofty",
            "Lonely",
            "Longhorn",
            "Loopy",
            "Loquacious",
            "Lordly",
            "Lovely",
            "Lucid",
            "Lucky",
            "Lugubrious",
            "Luminiferous",
            "Luminous",
            "Lurid",
            "Luscious",
            "Lusty"
        ],
        "animals": [
            "Labrador",
            "Ladybug",
            "Lagomorph",
            "Lamantin",
            "Lamb",
            "Lamprey",
            "Lapwing",
            "Lark",
            "Leafhopper",
            "Leech",
            "Lemming",
            "Lemur",
            "Leopard",
            "Leopon",
            "Liger",
            "Limpet",
            "Lion",
            "Lizard",
            "Llama",
            "Lobster",
            "Longhorn",
            "Loompa",
            "Loon",
            "Lorax",
            "Loris",
            "Louse",
            "lycaon",
            "Lynx"
        ],
        "version": { "initial_year": 23, "month": 4 }
    },
    "m": {
        "adjectives": [
            "Macular",
            "Mad",
            "Magical",
            "Magistical",
            "Magnanimous",
            "Magnetic",
            "Magnificent",
            "Maieutic",
            "Majestic",
            "Majim",
            "Majuscul",
            "Malapert",
            "Malicious",
            "Malodorous",
            "Malty",
            "Mammoth",
            "Mangy",
            "Manic",
            "Marauding",
            "Marvelous",
            "Massive",
            "Masterful",
            "Maudlin",
            "Maverick",
            "Mazarine",
            "Meandering",
            "Measured",
            "Meaty",
            "Melancholic",
            "Melic",
            "Melliferous",
            "Mellifluous",
            "Mellow",
            "Melodic",
            "Melodious",
            "Memorable",
            "Menacing",
            "Menstrual",
            "Mental",
            "Mercantile",
            "Mercenary",
            "Merciful",
            "Merciless",
            "Mercurial",
            "Meridian",
            "Merry",
            "Mesmeric",
            "Metallic",
            "Methodic",
            "Meticulous",
            "Metric",
            "Mettled",
            "Mickle",
            "Mighty",
            "Mild",
            "Militant",
            "Mindful",
            "Mini",
            "Minimalist",
            "Minty",
            "Miraculous",
            "Mischievous",
            "Misty",
            "Mobile",
            "Modest",
            "Modish",
            "Momentous",
            "Monadic",
            "Monastic",
            "Monty",
            "Moonlight",
            "Moral",
            "Mordant",
            "Moxious",
            "Murphy",
            "Muscled",
            "Mushy",
            "Musical",
            "Musky",
            "Muttering",
            "Myriad",
            "Mysterious",
            "Mystic",
            "Mystical",
            "Mythical"
        ],
        "animals": [
            "Macaque",
            "Macaw",
            "Macropod",
            "Maggots",
            "Magpie",
            "Mallard",
            "Mammoth",
            "Man",
            "Manatee",
            "Mandrill",
            "Manta",
            "Mantis",
            "Mara",
            "Marlin",
            "Marmot",
            "Marsupilami",
            "Marten",
            "Mastiff",
            "Meadowlark",
            "Meerkat",
            "Millipede",
            "Mink",
            "Minnow",
            "Mobutu",
            "Mockingbird",
            "Mole",
            "Mollusk",
            "Molly",
            "Monal",
            "Monca",
            "Mongoose",
            "Monitor",
            "Monkey",
            "Moose",
            "Mosquito",
            "Moth",
            "Motmot",
            "Mouflon",
            "Mouse",
            "Mule",
            "Muskox",
            "Muskrat",
            "Mustang",
            "Mutt",
            "Myna"
        ],
        "version": { "initial_year": 23, "month": 10 }
    },
    "n": {
        "adjectives": [
            "Nano",
            "Nasty",
            "Natty",
            "Naughty",
            "Nauseous",
            "Necrotising",
            "Nefarious",
            "Nerdy",
            "Nested",
            "Neurotic",
            "Nifty",
            "Nimble",
            "Nippy",
            "Noble",
            "Nocturnal",
            "Nomadic",
            "Normal",
            "Normandic",
            "Nostalgic",
            "Novel",
            "Null",
            "Nutritious"
        ],
        "animals": [
            "Naiad",
            "Narwhal",
            "Newt",
            "Nicholine",
            "Nightelf",
            "Nightingale",
            "Nightjar",
            "Ninf",
            "Numbat",
            "Nutria",
            "Nymph"
        ],
        "version": { "initial_year": 24, "month": 4 }
    },
    "o": {
        "adjectives": [
            "Obedient",
            "Oblivious",
            "Obnoxious",
            "Obsequious",
            "Observing",
            "Obstreperous",
            "Obtuse",
            "Obverse",
            "Obvious",
            "Occult",
            "Octal",
            "Octennial",
            "Odd",
            "Oily",
            "Okay",
            "Omnific",
            "Omniscient",
            "Oneiric",
            "Opportune",
            "Opportunistic",
            "Opulent",
            "Oral",
            "Orderly",
            "Ordinary",
            "Orgasmic",
            "Orgiastic",
            "Origamist",
            "Ornery",
            "Orthodox",
            "Orthogenic",
            "Oscillating",
            "Ostentatious"
        ],
        "animals": [
            "Ocelot",
            "Octopus",
            "Okapi",
            "Oompa-loompa",
            "Opossum",
            "Orangutan",
            "Orc",
            "Orca",
            "Oriole",
            "Oryx",
            "Ostrich",
            "Otter",
            "Owl",
            "Ox",
            "Oyster"
        ],
        "version": { "initial_year": 24, "month": 10 }
    },
    "p": {
        "adjectives": [
            "Pacific",
            "Panacean",
            "Parabolic",
            "Paradisaic",
            "Paramount",
            "Passive",
            "Peachy",
            "Pedantic",
            "Perceptive",
            "Perennial",
            "Perky",
            "Persistent",
            "Persnickety",
            "Perverse",
            "Phantastic",
            "Phantom",
            "Phat",
            "Philantropic",
            "Philosophical",
            "Phlegmatic",
            "Phlegmatic",
            "Phobic",
            "Phooka",
            "Photogenic",
            "Pickled",
            "Pimping",
            "Pink",
            "Plastered",
            "Plastic",
            "Playful",
            "Plonky",
            "Plucky",
            "Plutonic",
            "Poetic",
            "Pointy",
            "Pokey",
            "Polished",
            "Polyplastic",
            "Posh",
            "Powerful",
            "Powersaver",
            "Pragmatic",
            "Prancing",
            "Precise",
            "Precocious",
            "Predatory",
            "Predictive",
            "Predominate",
            "Prestidigious",
            "Pretty",
            "Priapic",
            "Prickly",
            "Prideful",
            "Prissy",
            "Pristine",
            "Proactive",
            "Prolific",
            "Proud",
            "Prowly",
            "Prudent",
            "Psychedelic",
            "Psychic",
            "Pulchritudinous",
            "Purring",
            "Pyroclastic",
            "Pyromaniac",
            "Pyrotechnic"
        ],
        "animals": [
            "Pademelon",
            "Panda",
            "Pangolin",
            "Panther",
            "Parakeet",
            "Parrot",
            "Partridge",
            "Peacock",
            "Peafowl",
            "Peccary",
            "Pegasus",
            "Pelican",
            "Penguin",
            "Pheasant",
            "Phoenix",
            "Pichi",
            "Pig",
            "Pigeon",
            "Piglet",
            "Pika",
            "Piraña",
            "Piranha",
            "Pitbull",
            "Pixi",
            "Platypus",
            "Polecat",
            "Pony",
            "Porcupine",
            "Porpoise",
            "Possum",
            "Primate",
            "Pronghorn",
            "Protozoa",
            "Puffin",
            "Puma",
            "Python"
        ],
        "version": { "initial_year": 25, "month": 4 }
    },
    "q": {
        "adjectives": [
            "Quaint",
            "Quaking",
            "Quantal",
            "Quantum",
            "Quarter",
            "Queer",
            "Querulous",
            "Quick",
            "Quiet",
            "Quintessential",
            "Quirky",
            "Quivering",
            "Quixotic"
        ],
        "animals": [
            "Qantassaurus",
            "Quagga",
            "Quahog",
            "Quail",
            "Quetzal",
            "Quetzalcoatl",
            "Quokka",
            "Quoll"
        ],
        "version": { "initial_year": 25, "month": 10 }
    },
    "r": {
        "adjectives": [
            "Rabid",
            "Racey",
            "Racing",
            "Radiant",
            "Radical",
            "Rambunctious",
            "Rampant",
            "Rancid",
            "Randy",
            "Rapid",
            "Raring",
            "Rational",
            "Raucous",
            "Raunchy",
            "Reasonable",
            "Rebel",
            "Rebellious",
            "Rednosed",
            "Reminiscent",
            "Resonant",
            "Rhapsy",
            "Ribald",
            "Rich",
            "Risky",
            "Roasted",
            "Robust",
            "Rolly",
            "Rowdy",
            "Rugged",
            "Runcible",
            "Ruthless"
        ],
        "animals": [
            "Rabbit",
            "Raccoon",
            "Raptor",
            "Raptor",
            "Rat",
            "Ratel",
            "Rattlesnake",
            "Raven",
            "Redbird",
            "Reindeer",
            "Rhea",
            "Rhino",
            "Ringtail",
            "Roach",
            "Roadrunner",
            "Robin",
            "Rook",
            "Rooster",
            "Rottweiler"
        ],
        "version": { "initial_year": 26, "month": 4 }
    },
    "s": {
        "adjectives": [
            "Saccate",
            "Saccharine",
            "Sagacious",
            "Sage",
            "Salivating",
            "Salubrious",
            "Sanguine",
            "Sarcastic",
            "Sardonic",
            "Sassy",
            "Saucy",
            "Scary",
            "Scrawny",
            "Screwy",
            "Secretive",
            "Selenographic",
            "Selenomorphic",
            "Sensual",
            "Sentimental",
            "Serendipitous",
            "Serious",
            "Servile",
            "Severe",
            "Sexy",
            "Sharp",
            "Shiny",
            "Shrewd",
            "Silly",
            "Skinny",
            "Slick",
            "Slim",
            "Slimmy",
            "Slinky",
            "Slippery",
            "Slippy",
            "Smelly",
            "Smiling",
            "Smitten",
            "Smooth",
            "Snappy",
            "Snazzy",
            "Sneaky",
            "Soaring",
            "Solid",
            "Sophisticated",
            "Sound",
            "Spastic",
            "Special",
            "Spectacular",
            "Speedy",
            "Spicy",
            "Spooky",
            "Spotty",
            "Sprightly",
            "Spunky",
            "Squidgy",
            "Squirly",
            "Stalwart",
            "Stealthy",
            "Stelene",
            "Steward",
            "Stocky",
            "Strapping",
            "Streetwise",
            "Stressful",
            "Striking",
            "Stunning",
            "Stupendous",
            "Sturdy",
            "Suave",
            "Suctorial",
            "Sudden",
            "Sultry",
            "Superstitious",
            "Surreptitious",
            "Swarthy"
        ],
        "animals": [
            "Sabretooth",
            "Salamander",
            "Salmon",
            "Saola",
            "Sasquatch",
            "Satyr",
            "Scallop",
            "Scorpion",
            "Scorpionfish",
            "Scottie",
            "Seadonkey",
            "Seagull",
            "Seahorse",
            "Seal",
            "Serval",
            "Shark",
            "Sheep",
            "Shih-Tzu",
            "Shrew",
            "Silverfish",
            "Sitatunga",
            "Skunk",
            "Slippershell",
            "Sloth",
            "Snail",
            "Snake",
            "Snake",
            "Snapper",
            "Snapping-Turtle",
            "Snow-Leopard",
            "Spaniel",
            "Sparrow",
            "Spider",
            "Springbok",
            "Squid",
            "Squirrel",
            "Stallion",
            "Starfish",
            "Stingray",
            "Sukko",
            "Suricata",
            "Suricate",
            "Swallow",
            "Swan",
            "Swordfish"
        ],
        "version": { "initial_year": 26, "month": 10 }
    },
    "t": {
        "adjectives": [
            "Tabby",
            "Tactful",
            "Talented",
            "Tame",
            "Tantric",
            "Tangible",
            "Tangled",
            "Tanked",
            "Tasteful",
            "Tasty",
            "Taught",
            "Teachable",
            "Tedious",
            "Teeming",
            "Tempean",
            "Temperamental",
            "Temperate",
            "Tenable",
            "Tenacious",
            "Tender",
            "Terrific",
            "Thankful",
            "Therapeutic",
            "Thorough",
            "Thoughtful",
            "Thrashing",
            "Thrifty",
            "Thrilled",
            "Thrilling",
            "Thriving",
            "Ticklish",
            "Tidy",
            "Tight",
            "Timely",
            "Timeless",
            "Tiny",
            "Tipsy",
            "Tireless",
            "Tolerant",
            "Touching",
            "Tough",
            "Touted",
            "Trailblazing",
            "Tranquil",
            "Transgendered",
            "Transcendent",
            "Transcendental",
            "Transsexual",
            "Transparent",
            "Transpicuous",
            "Traumatized",
            "Traveled",
            "Treasured",
            "Tremendous",
            "Tretis",
            "Tricky",
            "Trim",
            "Trippy",
            "Triumphant",
            "True",
            "Truculent",
            "Trusted",
            "Trustful",
            "Trustworthy",
            "Trusty",
            "Truthful",
            "Twirling",
            "Tweaky",
            "Twitchy",
            "Tympanic"
        ],
        "animals": [
            "Tahr",
            "Taipan",
            "Takahe",
            "Tamandua",
            "Tapir",
            "Tarantula",
            "Tarsier",
            "Tasmanian-Devil",
            "Tenrec",
            "Termite",
            "Terrapin",
            "Terrier",
            "Tiger",
            "Tigon",
            "Tigerfish",
            "Toad",
            "Tortoise",
            "Toucan",
            "Trogon",
            "Troll",
            "Trout",
            "Tuatara",
            "Turkey",
            "Turtle",
            "Tassie-Thylacine",
            "Tazzy-Thylacine",
            "Tesselated"
        ],
        "version": { "initial_year": 27, "month": 4 }
    },
    "u": {
        "adjectives": [
            "Ubuntu",
            "Ugly",
            "Ultimate",
            "Unadulterated",
            "Unapologetic",
            "Unassailable",
            "Unassuming",
            "Unbelievable",
            "Ultrarelativistic",
            "Unctuous",
            "Underrated",
            "Underrepresented",
            "Understanding",
            "Undulating",
            "Unplugged",
            "Unpretentious",
            "Unrated",
            "Unrepentant",
            "Unruly",
            "Untamed",
            "Untapped",
            "Uppity",
            "Undead",
            "Uproarious",
            "Ubiquitous",
            "Utopic"
        ],
        "animals": [
            "Uakari",
            "Ubuntu",
            "Uglybird",
            "Utonagan",
            "Ungulate",
            "Unicorn",
            "Urchin",
            "Urial",
            "Urubu",
            "Urukai",
            "Uguisu",
            "Umbrian",
            "Uintathere"
        ],
        "version": { "initial_year": 27, "month": 10 }
    },
    "v": {
        "adjectives": [
            "Valiant",
            "Vast",
            "Veracious",
            "Versatile",
            "Veteran",
            "Vibrant",
            "Violent",
            "Viral",
            "Virtuoso",
            "Vitriolic",
            "Vivacious",
            "Vivid",
            "Vociferous",
            "Volatile",
            "Voluptuous",
            "Vomiting",
            "Voracious"
        ],
        "animals": [
            "Veery",
            "Velociraptor",
            "Vervet",
            "Vicuña",
            "Viper",
            "Vixen",
            "Vizcacha",
            "Vole",
            "Vulture"
        ],
        "version": { "initial_year": 28, "month": 4 }
    },
    "w": {
        "adjectives": [
            "Wandering",
            "Warm",
            "Warty",
            "Western Tragopan",
            "Wild",
            "Wily",
            "Wimpy",
            "Windy",
            "Witty",
            "Wobbly",
            "Wonderful",
            "Woolly"
        ],
        "animals": [
            "Wallaby",
            "Walrus",
            "Warthog",
            "Wasp",
            "Weasel",
            "Werewolf",
            "Weevil",
            "Whale",
            "Wildebeest",
            "Wolf",
            "Wolverine",
            "Wombat",
            "Woodchuck",
            "Woodpeck",
            "Worm",
            "Wren",
            "Weka"
        ],
        "version": { "initial_year": 28, "month": 10 }
    },
    "x": {
        "adjectives": [
            "Xanthic",
            "Xenodochial",
            "Xenolithic",
            "Xenophobic",
            "Xenial",
            "Xeric",
            "Xintillating",
            "Xiphoid",
            "Xylographic",
            "Xylophonic"
        ],
        "animals": [
            "Xebu",
            "Xenarthra",
            "Xenomorph",
            "Xenoturbella",
            "Xenops",
            "Xerus",
            "Xiphias",
            "Xipho",
            "X-ray fish",
            "Xue Bao",
            "Xoloitzcuintle",
            "Xólotl"
        ],
        "version": { "initial_year": 29, "month": 4 }
    },
    "y": {
        "adjectives": [
            "Yakkity",
            "Yammering",
            "Yelping",
            "Yiffy",
            "Yippity",
            "Youthful",
            "Yummy",
            "Yodeling"
        ],
        "animals": [
            "Yaffle",
            "Yak",
            "Yellowjacket",
            "Yeti"
        ],
        "version": { "initial_year": 29, "month": 10 }
    },
    "z": {
        "adjectives": [
            "Zany",
            "Zealous",
            "Zen",
            "Zesty",
            "Zippy",
            "Zooming",
            "Zarking"
        ],
        "animals": [
            "Zealot",
            "Zebra",
            "Zebrafish",
            "Zebu",
            "Zorilla",
            "Zorse"
        ],
        "version": { "initial_year": 30, "month": 4 }
    }
}
