<section class="row">
    <aside class="col-md-3">
    </aside>
    <main class="col-md-6">
        <h1 class="text-center">About</h1>
        <p>
            Hi, my name is Fernando Crespo. I made this for fun and to learn Angular. I am a developer who likes everything about
            tech.
        </p>
        <p>
            If you wanna know more about me or anything that I do, head to my <a href="//twitter.com/fcrespo82"
                target="_blank">Twitter</a> or <a href="//github.com/fcrespo82" target="_blank">GitHub</a>.
        </p>
    </main>
    <aside class="col-md-3">
    </aside>
</section>