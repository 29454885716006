<nav class="navbar navbar-expand-lg navbar-dark bg-ubuntu">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="generator" routerLinkActive="active">Generator</a>
        <a class="nav-item nav-link" routerLink="about" routerLinkActive="active">About</a>
    </div>
</nav>
<div class="text-center">
    <div class="jumbotron jumbotron-fluid">
        <h1 class="text-white display-4">
            <img src="/assets/cof_black_hex.png" alt="Ubuntu Logo" class="logo" /> Ubuntu Name Generator
        </h1>
    </div>
</div>
<div class="container-fluid">
    <router-outlet></router-outlet>
</div>