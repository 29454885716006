<div>
    <meta name="twitter:card" content="summary" />
    <meta property="og:title" content="Ubuntu Name Generator">
    <meta property="og:description" content="#ubuntuNameGenerator">
    <meta property="og:image" [content]="image">
    <meta property="og:url" [content]="link">
</div>
<section class="row">
    <aside class="col-md-3">
        <photo [url]="image" [title]="animal" [caption]="photographer"></photo>
    </aside>
    <main class="text-center col-md-6">
        <h1>{{ubuntuName}}</h1>
        <h3 class="text-muted">{{ubuntuVersion}}</h3>
        <br/>
        <button class="btn btn-primary" (click)="generateName()">Generate</button>
        <div>
            <share-buttons [include]="['facebook','twitter','copy','reddit','whatsapp','messenger','telegram','email']"
                [show]="3" [size]="-4" [description]="'Look at this Ubuntu Name #ubuntuNameGenerator!'"></share-buttons>
        </div>
    </main>
    <aside class="col-md-3">
        <h4>Real Names:</h4>
        <div class="list-group realNames">
            <a *ngFor="let name of realNames; let i = index" [routerLink]="['/generator', name.version]" class="list-group-item list-group-item-action"
                routerLinkActive="active">
                {{name.version}} -
                <strong>{{name.adjective}} {{name.animal}}</strong>
            </a>
        </div>
    </aside>
</section>